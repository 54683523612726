// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_label {
    width: 40px;
    height: 20px;
    text-align: center;
    line-height: 36px;
    font-size: 30px;
    cursor: pointer;
    display: inline-block;
    margin-left: 0px;
    margin-right: 20px;
    user-select: none;
  }
  
  
  .checkbox_label > * {
    position: absolute;
    transition: opacity .1s ease;
   }
  
  input + label > :first-child {
      color: #b4b4b4;
  
  }
  
  input + label > :last-child {
      color: red;
      font-weight: 700;
      font-size: 25px;
  }
  
  input + label > :last-child {
    opacity: 0;
  }
  
  input:checked  + label > :first-child {
    opacity: 0;
  }
  
  input:checked  + label > :last-child {
    opacity: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/experiment/experiment-experiment-component/experiment-experiment.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;EACnB;;;EAGA;IACE,kBAAkB;IAClB,4BAA4B;GAC7B;;EAED;MACI,cAAc;;EAElB;;EAEA;MACI,UAAU;MACV,gBAAgB;MAChB,eAAe;EACnB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".checkbox_label {\n    width: 40px;\n    height: 20px;\n    text-align: center;\n    line-height: 36px;\n    font-size: 30px;\n    cursor: pointer;\n    display: inline-block;\n    margin-left: 0px;\n    margin-right: 20px;\n    user-select: none;\n  }\n  \n  \n  .checkbox_label > * {\n    position: absolute;\n    transition: opacity .1s ease;\n   }\n  \n  input + label > :first-child {\n      color: #b4b4b4;\n  \n  }\n  \n  input + label > :last-child {\n      color: red;\n      font-weight: 700;\n      font-size: 25px;\n  }\n  \n  input + label > :last-child {\n    opacity: 0;\n  }\n  \n  input:checked  + label > :first-child {\n    opacity: 0;\n  }\n  \n  input:checked  + label > :last-child {\n    opacity: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
