// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file-upload {
    border: 1px solid transparent;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #fff;
}

.custom-file-upload:hover{
    color: #fff;
    background-color: #148EA1;
    border-color: #148EA1;
} 

.custom-file-upload:focus-within{
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgb(58 176 195 / 50%);
} 

.custom-file-upload:active{
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgb(58 176 195 / 50%);
} `, "",{"version":3,"sources":["webpack://./src/components/home/constructor/constructor-practice/constructor-practice.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,qBAAqB;IACrB,iBAAiB;IACjB,eAAe;IACf,sBAAsB;IACtB,yBAAyB;IACzB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,8CAA8C;AAClD;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,8CAA8C;AAClD","sourcesContent":[".custom-file-upload {\n    border: 1px solid transparent;\n    display: inline-block;\n    padding: 6px 12px;\n    cursor: pointer;\n    border-radius: 0.25rem;\n    background-color: #17a2b8;\n    border-color: #17a2b8;\n    color: #fff;\n}\n\n.custom-file-upload:hover{\n    color: #fff;\n    background-color: #148EA1;\n    border-color: #148EA1;\n} \n\n.custom-file-upload:focus-within{\n    color: #fff;\n    background-color: #138496;\n    border-color: #117a8b;\n    box-shadow: 0 0 0 0.2rem rgb(58 176 195 / 50%);\n} \n\n.custom-file-upload:active{\n    color: #fff;\n    background-color: #138496;\n    border-color: #117a8b;\n    box-shadow: 0 0 0 0.2rem rgb(58 176 195 / 50%);\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
